@import "../../styles/vars";

$border: 1px;

.button-gradient {
  font-family: 'DM Sans', sans-serif;
  height: 58px;
  cursor: pointer;

  padding: 5px;
  box-sizing: border-box;

  font-weight: 700;
  font-size: 14px;
  border: 5px solid $black;
  line-height: 18px;
  background: $gradient;

  &:hover {
    background: $dark-gray;

    .button-gradient__text {
      background: $gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .button-gradient__content {
      background: $dark-gray;
    }
  }

  &__text {
    transition: all 200ms;
    color: $black;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: all 200ms;
    border-radius: 80px;

    width: 100%;
    height: 100%;
    background: $gradient;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    .button-gradient__text {
      opacity: 0.5;
    }
  }
}
