@import "../../ui-kit/styles/vars";

.header {
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 40px;
    z-index: 1;
    top: 0;
    left: 0;

    @media screen and (max-width: 600px) {
        height: 68px;
        padding: 0 16px;

        &__logo {
            height: 40px;
            width: 40px;
        }

        #connect-button-metamask {
            display: none;
        }
        #connect-button-wallet-connect {
            display: flex !important;
        }
    }

    &__logo {
        height: 48px;
        width: 48px;
    }

    &__address {
        cursor: pointer;
        background: #313131;
        border-radius: 40px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 20px;
        width: 120px;
        height: 40px;
    }

    &__navbar {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__button {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #FFFFFF;
        height: 40px;
        width: 120px;
        cursor: pointer;
        border-radius: 40px;

        display: flex;
        justify-content: center;
        align-items: center;

        background: $black;
        background-clip: padding-box;
        border: solid 1px transparent;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            z-index: -1;
            margin: -1px;
            border-radius: inherit;
            background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
        }
    }

    #connect-button-wallet-connect {
        display: none;
    }
}
