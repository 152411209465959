.App {
    padding-top: 120px;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;

    &__container {
        width: 100%;
        min-height: calc(100vh - 280px);
        color: #FFFFFF;
        margin-bottom: 76px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &--padded {
            padding-top: 160px;
        }
    }
}
