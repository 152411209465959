.allowances {
  &-data {
    padding-bottom: 14px;

    &__title {
      a {
        color: white;

        & span:first-child {
          background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
      }
    }

    &__allowances {
      &__revoke-button {
        margin-left: 10px;
      }

      &__item {
        display: flex;
        align-items: center;
        padding-bottom: 8px;

        &:last-child {
          padding-bottom: 0;
        }

        a {
          background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          &:hover {
            opacity: 0.8;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__status {
    padding-top: 14px;
    text-align: center;
  }
}