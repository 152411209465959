@import "../../styles/vars";

$border: 1px;

.button {
  font-family: 'DM Sans', sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  background: $black;
  padding: 12px 18px;
  color: #FFFFFF;
  cursor: pointer;

  &:hover {
    background: $dark-gray;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: rgba(255,255,255, 0.5);
  }
}
